.sponsorsTitleCard {
    width: 100%;
    height: 90vh;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
}

.sponsorsTitleCard h1 {
  position: relative;
  font-size: 12vw;
  font-weight: 800;
  text-align: center;
  font-family: "Bebas Neue";
}

.sponsorsColorOverlay {
    height: 90vh; /* make sure this is the same height as titlecard */
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    pointer-events: none;
}

.sponsors {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    background-color: rgb(107, 144, 128);
  }
  
  .sponsors h1 {
    font-family: "Bebas Neue";
    font-weight: 300;
    font-size: 4vw;
    color: white;
    text-align: center;
  
    margin-top: 6vh;
  }
  
  .sponsors p {
    color: white;
    text-align: center;
    margin-bottom: 3vh;
    font-size: 20px;
  }
  
  .sponsorsList {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
  
  .sponsors img {
    height: 10vh;
    object-fit: contain;
    margin-left: 1vw;
  }
  
  .sponsors a {
    height: fit-content;
    width: 15vw;
    background-color: rgb(0, 78, 100);
    border-radius: 5px;
  
    color: white;
    text-decoration: none;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  
    padding: 2vh;
    margin-bottom: 6vh;
  }
  